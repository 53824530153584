<template>
  <div class="main-info">
    <span class="main-info__icon icon-info" />

    <p class="main-info__description">
      {{ description }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    description: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.main-info {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &__icon {
    display: flex;
    width: 24px;
    height: 24px;
    margin: 0 8px 0 0;
    background-color: $dark-fifth;
  }

  &__description {
    width: calc(100% - 32px);
    color: $dark-fifth;
    @include text-2;
  }
}
</style>

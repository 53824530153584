<script>
import {
  getDate,
  getMonth,
  getYear,
  format,
  differenceInCalendarDays,
} from "date-fns";
import ruLocale from "date-fns/locale/ru";

export default {
  props: {
    week: {
      type: Object,
      required: true,
    },

    maxAmount: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    lineWidthInPercent() {
      return `${(this.week.amount / this.maxAmount) * 100}%`;
    },

    showCurrentWeek() {
      return (
        differenceInCalendarDays(new Date(), new Date(this.week.week_start)) < 7
      );
    },

    showPreviousWeek() {
      return (
        differenceInCalendarDays(new Date(), new Date(this.week.week_start)) >=
          7 &&
        differenceInCalendarDays(new Date(), new Date(this.week.week_start)) <
          14
      );
    },

    formattedDate() {
      if (
        getMonth(new Date(this.week.week_start)) ===
        getMonth(new Date(this.week.week_end))
      ) {
        return `${getDate(new Date(this.week.week_start))} - ${getDate(
          new Date(this.week.week_end)
        )} ${format(new Date(this.week.week_start), "LLLL", {
          locale: ruLocale,
        })} ${getYear(new Date(this.week.week_start))}`;
      } else if (
        getMonth(new Date(this.week.week_start)) !==
          getMonth(new Date(this.week.week_end)) &&
        getYear(new Date(this.week.week_start)) ===
          getYear(new Date(this.week.week_end))
      ) {
        return `${getDate(new Date(this.week.week_start))} ${format(
          new Date(this.week.week_start),
          "LLLL",
          {
            locale: ruLocale,
          }
        )} - ${getDate(new Date(this.week.week_end))} ${format(
          new Date(this.week.week_end),
          "LLLL",
          {
            locale: ruLocale,
          }
        )} ${getYear(new Date(this.week.week_start))}`;
      } else if (
        getMonth(new Date(this.week.week_start)) !==
          getMonth(new Date(this.week.week_end)) &&
        getYear(new Date(this.week.week_start)) !==
          getYear(new Date(this.week.week_end))
      ) {
        return `${getDate(new Date(this.week.week_start))} ${format(
          new Date(this.week.week_start),
          "LLLL",
          {
            locale: ruLocale,
          }
        )} - ${getDate(new Date(this.week.week_end))} ${format(
          new Date(this.week.week_end),
          "LLLL",
          {
            locale: ruLocale,
          }
        )} ${getYear(new Date(this.week.week_start))}-${getYear(
          new Date(this.week.week_end)
        )}`;
      }
      return "Invalid Date";
    },
  },
};
</script>

<template>
  <div class="financial-cycles-history-item">
    <p class="financial-cycles-history-item__date">
      {{ formattedDate }}
      <span v-if="showCurrentWeek">Текущая неделя</span>
      <span v-if="showPreviousWeek">Предыдущая неделя</span>
    </p>

    <div
      class="financial-cycles-history-item__line"
      :class="{
        'financial-cycles-history-item__line_empty': week.amount === 0,
      }"
    >
      <span :style="{ width: lineWidthInPercent }"></span>
      <p v-if="week.amount > 0" class="financial-cycles-history-item__amount">
        {{ week.amount }}
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.financial-cycles-history-item {
  margin-bottom: $space-l;

  &__date {
    display: flex;
    align-items: center;
    @include text-2;
    color: $dark-primary;

    span {
      display: block;
      margin-left: $space-s;
      color: $dark-sixth;
    }
  }

  &__line {
    display: flex;
    align-items: center;
    margin-top: $space-s;

    span {
      display: block;
      height: 20px;
      border-radius: 8px;
      background-color: $gold-main;
    }

    &_empty {
      margin-top: $space-xs;

      span {
        height: 8px;
        width: 8px !important;
        background-color: $light-fourth;
      }
    }
  }

  &__amount {
    @include text-2-bold;
    color: $dark-primary;
    margin-left: 12px;
  }
}
</style>

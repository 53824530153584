<script>
import { mapState, mapActions } from "vuex";
import { debounce } from "throttle-debounce";
import ButtonRedirect from "@/components/helpers/ButtonRedirect";
import AccountSelector from "@/components/AccountSelector";
import MainInfo from "@/components/helpers/MainInfo";
import MainDateRange from "@/components/helpers/MainDateRange";
import FinancialCyclesHistoryList from "@/components/qualifications/FinancialCyclesHistoryList";
import MainLoader from "@/components/helpers/MainLoader";

const QUALIFICATIONS_REQUIREMENTS = {
  start: {
    currentWeek: 3,
    previousWeek: 3,
  },

  expert: {
    currentWeek: 6,
    previousWeek: 6,
  },

  star: {
    currentWeek: 20,
    previousWeek: 20,
  },

  double_star: {
    currentWeek: 40,
    previousWeek: 40,
  },

  diamond: {
    currentWeek: 80,
    previousWeek: 80,
  },

  double_diamond: {
    currentWeek: 120,
    previousWeek: 120,
  },

  grand_diamond: {
    currentWeek: 200,
    previousWeek: 200,
  },

  diamond_elite: {
    currentWeek: 350,
    previousWeek: 350,
  },

  ambassador: {
    currentWeek: 600,
    previousWeek: 600,
  },
};

export default {
  components: {
    MainLoader,
    FinancialCyclesHistoryList,
    MainDateRange,
    MainInfo,
    ButtonRedirect,
    AccountSelector,
  },

  data() {
    return {
      loadingFinanceCycles: true,

      params: {
        binaryAccountId: null,
        page: 1,
        dateRange: {
          start: "",
          end: "",
        },
      },

      lastPage: 1,
    };
  },

  watch: {
    selectedBinaryAccount() {
      this.params.binaryAccountId = this.selectedBinaryAccount.id;
    },

    computedParams: {
      deep: true,
      handler(newValue, oldValue) {
        if (
          (newValue.dateRange?.start !== oldValue.dateRange?.start ||
            newValue.dateRange?.end !== oldValue?.dateRange?.end ||
            newValue.dateRange === null ||
            newValue.binaryAccountId !== oldValue.binaryAccountId) &&
          oldValue.page !== 1
        ) {
          this.params.page = 1;
        }

        this.loadFinancialCyclesRequest(this.computedParams);
      },
    },
  },

  computed: {
    ...mapState({
      financialCycles: (state) => state.financialCycles.financialCycles,
      selectedBinaryAccount: (state) => state.auth.selectedBinaryAccount,
      financialCyclesWeeks: (state) =>
        state.financialCycles.financialCyclesWeeks,
    }),

    currentQualificationRequirements() {
      if (this.selectedBinaryAccount?.qualification) {
        return QUALIFICATIONS_REQUIREMENTS[
          this.selectedBinaryAccount.qualification.code
        ];
      }

      return null;
    },

    computedParams() {
      return Object.assign({}, this.params);
    },
  },

  methods: {
    ...mapActions({
      loadFinancialCycles: "financialCycles/loadFinancialCycles",
    }),

    loadFinancialCyclesRequest: debounce(500, function (params) {
      this.loadFinancialCycles(params).then((response) => {
        this.lastPage = response.data.meta.LastPage;
      });
    }),
  },

  mounted() {
    this.params.binaryAccountId = this.selectedBinaryAccount.id;
  },
};
</script>

<template>
  <div class="financial-cycles">
    <div class="financial-cycles__title">
      <button-redirect
        text="Назад к квалификациям"
        @click="$router.push({ name: 'Qualifications' })"
      />

      <h1>Командные циклы</h1>
    </div>

    <div v-if="selectedBinaryAccount" class="account-selector-wrapper">
      <account-selector />
    </div>

    <div class="financial-cycles__content">
      <main-info
        description="Один командный цикл - это сокращение 10 CV в правой и левой командах"
      />

      <main-loader v-if="!financialCycles" />

      <div
        v-if="financialCycles && currentQualificationRequirements"
        class="financial-cycles__progress progress"
      >
        <h5 class="progress__title">Для следующей квалификации</h5>

        <ul class="qualification-progress__list progress__list">
          <li
            class="qualification-progress__item progress-item"
            :class="{
              'progress-item--done':
                financialCycles.weeks.last_week.amount >=
                currentQualificationRequirements.previousWeek,
            }"
          >
            <p class="progress-item__title">Циклов за прошлую неделю</p>
            <div class="progress-item__info">
              {{ financialCycles.weeks.last_week.amount }}/{{
                currentQualificationRequirements.previousWeek
              }}
            </div>
          </li>

          <li
            class="qualification-progress__item progress-item"
            :class="{
              'progress-item--done':
                financialCycles.weeks.current_week.amount >=
                currentQualificationRequirements.currentWeek,
            }"
          >
            <p class="progress-item__title">Циклов за текущую неделю</p>
            <div class="progress-item__info">
              {{ financialCycles.weeks.current_week.amount }}/{{
                currentQualificationRequirements.currentWeek
              }}
            </div>
          </li>
        </ul>
      </div>

      <div v-if="financialCycles" class="financial-cycles__history history">
        <h5 class="history__title">История</h5>

        <MainDateRange
          v-model="params.dateRange"
          label="Показать за период"
          :max-date="new Date()"
        />

        <financial-cycles-history-list
          :weeks="financialCyclesWeeks"
          :current-page="params.page"
          :last-page="lastPage"
          @loadNextPage="params.page++"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.financial-cycles {
  &__title,
  &__content {
    padding: $space-xxl $space-xxl $space-xxxl;

    @media (min-width: 426px) and (max-width: 576px) {
      padding: $space-xxl $space-xl;
    }

    @media (max-width: 425px) {
      padding: $space-xxl $space-l;
    }

    @media (max-width: 375px) {
      padding: $space-xxl $space-m;
    }
  }

  .button-redirect {
    margin-bottom: $space-xs;
  }

  .main-loader {
    justify-content: flex-start;
  }

  &__title {
    h1 {
      @include title-3;
    }
  }

  &__content {
    background-color: $light-primary;
  }

  .main-info {
    max-width: 340px;
    margin-bottom: $space-xxl;
  }

  .progress {
    margin-bottom: $space-xxl;

    &__title {
      @include body-1-bold;
      color: $dark-primary;
      margin-bottom: $space-l;
    }

    &__list {
      max-width: 320px;
    }
  }

  .history {
    &__title {
      @include body-1-bold;
      color: $dark-primary;
      margin-bottom: $space-m;
    }

    .main-date-range {
      margin-bottom: 40px;
    }
  }
}
</style>

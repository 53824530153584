<script>
import { mapGetters } from "vuex";
import MainLoader from "@/components/helpers/MainLoader";
import FinancialCyclesHistoryItem from "@/components/qualifications/FinancialCyclesHistoryItem";

export default {
  components: { FinancialCyclesHistoryItem, MainLoader },

  props: {
    currentPage: {
      type: Number,
      default: 1,
    },

    lastPage: {
      type: Number,
      default: 1,
    },

    weeks: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      loadingObserver: null,
    };
  },

  watch: {
    lastPage() {
      if (this.lastPage > 1 && this.loadingObserver === null) {
        this.setLoadingObserver();
      }
    },
  },

  computed: {
    ...mapGetters({
      getFinancialCyclesWeeksAmounts:
        "financialCycles/getFinancialCyclesWeeksAmounts",
    }),

    maxWeeksAmount() {
      return Math.max(...this.getFinancialCyclesWeeksAmounts);
    },
  },

  methods: {
    setLoadingObserver() {
      this.loadingObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (this.currentPage >= this.lastPage) {
              return;
            }
            setTimeout(() => {
              this.$emit("loadNextPage");
            }, 1000);
          }
        });
      });

      this.loadingObserver.observe(document.querySelector("#history-loader"));
    },
  },

  mounted() {
    if (this.lastPage > 1) {
      this.setLoadingObserver();
    }
  },
};
</script>

<template>
  <div class="financial-cycles-history-list">
    <financial-cycles-history-item
      v-for="(week, index) in weeks"
      :key="index"
      :week="week"
      :max-amount="maxWeeksAmount"
    />

    <main-loader v-show="currentPage < lastPage" id="history-loader" />
  </div>
</template>

<style lang="scss" scoped>
.financial-cycles-history-list {
  max-width: 1000px;

  .main-loader {
    justify-content: flex-start;
    padding: 0;
  }
}
</style>
